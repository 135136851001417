@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Regular Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Regular.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Light Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Light.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Medium Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Medium.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Bold Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Bold.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Black Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Black.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Regular Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Regular.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Regular Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 400;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Regular.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Light Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Light.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Light Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 300;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Light.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Medium Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Medium.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Medium Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 500;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Medium.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Bold Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Bold.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Bold Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 700;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Bold.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Black Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans Condensed'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Condensed Black.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Black Italic.woff') format('woff');
}
@font-face {
    font-family: 'Zin Sans Extended';
    font-style: normal;
    font-weight: 900;
    src: local('Zin Sans Extended'), url('https://fonts.cdnfonts.com/s/28729/Zin Sans Extended Black.woff') format('woff');
}


